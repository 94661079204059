<script lang="ts">
	import { type MetaField } from '$lib/utils';
	import Image from '$lib/components/Image/Image.svelte';
	import { page } from '$app/stores';
	import { resolveRoute } from '$app/paths';
	import type { CompleteVariant } from '$lib/types';
	import {
		type Collections,
		formatProductTitle,
		parseHighlight,
		transformProductCollectionsToBranch
	} from '$lib/utils/product';
	import slug from 'slug';
	import { getCollectionPath } from '$lib/utils/collectionTree';

	export let title: string;
	export let variantTitle: string | undefined = undefined;
	export let imageUrl: string;
	export let rawHighlights: MetaField;
	export let collections: Collections;
	export let handle: string;
	export let selectedOptions: CompleteVariant['selectedOptions'] = [];

	$: highlights = parseHighlight(rawHighlights);
</script>

<div class="flex gap-4">
	<div class="w-20 h-20 flex-none bg-accent-primary_005 p-1">
		<Image width={64} height={64} src={imageUrl} class="w-full object-cover" />
	</div>
	<div class="flex-1 flex flex-col">
		<div class="flex justify-between w-full">
			<div class="flex flex-col">
				<a
					style:text-transform="none"
					href={resolveRoute('/[locale]-[country]/[branch=branch]/[slug]/[handle]', {
						...$page.params,
						slug: slug(title),
						handle,
						branch:
							getCollectionPath(collections)?.[0]?.url_key ||
							transformProductCollectionsToBranch(collections) ||
							'hund'
					})}
					class="text-h-16 text-opacity-75 font-extrabold line-clamp-2 hover:text-dark-secondary"
				>
					{formatProductTitle(title, variantTitle)}
				</a>
				{#if highlights}
					<p class="line-clamp-3">
						{highlights}
					</p>
				{/if}
				{#if selectedOptions.length > 0}
					{#each selectedOptions.filter((s) => s.name !== 'single') as { name, value }}
						<p class="text-p-12 mt-0.5">
							{name}: {value}
						</p>
					{/each}
				{/if}
			</div>
			<div class="flex flex-col gap-2.5 items-end">
				<slot name="price" />
			</div>
		</div>
		<slot name="cart-controls" />
	</div>
</div>
