<script lang="ts">
	import type { CompleteVariant, Money } from '$lib/types';
	import { formatPrice } from '$lib/utils/product';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { getTranslate, T } from '@tolgee/svelte';

	export let price: Money | undefined = undefined;
	export let measurementPrice: Money | undefined | null = undefined;
	export let referenceUnit: string | undefined = undefined;
	export let measurement: CompleteVariant['unitPriceMeasurement'] | undefined = undefined;
	export let wrap = false;
	export let showNote = false;
	let className = '';
	export { className as class };

	const { t } = getTranslate();
	$: hasBasePrice = !!measurementPrice;
	$: formattedPrice = formatPrice(measurementPrice || price);
</script>

{#if formattedPrice}
	<span
		class={classes(
			'text-p-12 !normal-case inline-flex whitespace-nowrap flex flex-col',
			wrap && 'md:whitespace-pre-wrap',
			className
		)}
	>
		<T
			keyName="formattedPrice_per_unit"
			params={{
				price: formattedPrice,
				unit: hasBasePrice
					? `${measurement?.referenceValue} ${$t(
							`reference_unit_${measurement?.referenceUnit ?? referenceUnit ?? ''}`
						)}`
					: $t(`reference_unit_${referenceUnit}`) ?? $t('fallbackUnit')
			}}
		/>
		{#if showNote}
			<div>
				<T keyName="product.pricingNote" />
			</div>
		{/if}
	</span>
{/if}
