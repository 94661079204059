<script lang="ts">
	import type { CartLine, Money } from '$lib/types';
	import ProductPrice from '$lib/components/Product/ProductPrice.svelte';
	import AmountSelection from '$lib/components/Product/AmountSelection.svelte';
	import UnitPrice from '$lib/components/Product/UnitPrice.svelte';
	import Button from '$lib/components/Button/Button.svelte';
	import { getTranslate } from '@tolgee/svelte';
	import { cart } from '$lib/store/cart.store';
	import ProductCardHorizontal from '$lib/components/Product/ProductCardHorizontal.svelte';
	import SaleChip from '$lib/components/Product/SaleChip.svelte';
	import { GetSDSOnlyInfoStore } from '$houdini';
	import { languageStore } from '$lib/store/language.store';
	import { mapToNode, percentOf } from '$lib/utils/product';
	import { parseImageUrls } from '$lib/utils/image';
	import { addSDS } from '$lib/utils/sds';
	import { ensureCountry, ensureLocale } from '$lib/utils/i18n';
	import { isNetworkError } from '$lib/utils';

	export let data: CartLine;
	const store = new GetSDSOnlyInfoStore();
	$: sdsVariant = mapToNode($store.data?.sdsProduct?.variants).find(
		(sdsV) => sdsV.sku?.value === data.merchandise?.sku?.value
	);
	$: maxAvailable = Math.max(
		data.merchandise?.quantityAvailable || 0,
		sdsVariant?.quantityAvailable || 0
	);

	$: imageData = parseImageUrls(data.merchandise.image_urls)?.[0];
	let amount = data.quantity;
	let cancelChangeAction;

	const { t } = getTranslate();

	const handleChange = (changedQuantity: number) => {
		console.log('change', data.quantity, '->', changedQuantity);
		if (changedQuantity !== data.quantity) {
			if (cancelChangeAction) {
				clearTimeout(cancelChangeAction);
			}
			console.log('schedule change', data.quantity, '->', changedQuantity);
			cancelChangeAction = setTimeout(() => {
				if (!sdsVariant) {
					return;
				}
				const quantityTotal = $cart.getQuantity(data.merchandise.sku?.value || '');
				const otherQuantity = quantityTotal - data.quantity;

				cart.changeQuantity({
					sku: data.merchandise.sku?.value || '',
					variantId: data.merchandise.id,
					sdsId: sdsVariant.id,
					sdsThreshold: Number(data.merchandise.sds_qty?.value || '10') || 10,
					quantity: changedQuantity + otherQuantity,
					handle: data.merchandise?.product?.handle || '',
					sdsOnHand: sdsVariant.quantityAvailable || 0,
					avantradoOnHand: data.merchandise.quantityAvailable || 0
				});
			}, 500);
		}
	};

	const handleTopLevel = (changedTop: number) => {
		if (changedTop !== amount) {
			amount = changedTop;
		}
	};

	$: handleChange(amount);
	$: handleTopLevel(data.quantity);

	const applyQuantityToMoney = (money: Money, quantity: number) => {
		return {
			...money,
			amount: quantity > 0 ? money.amount * quantity : money.amount
		};
	};

	$: priceData = (() => {
		const compareAtWithQuantity = data.merchandise.compareAtPrice
			? applyQuantityToMoney(data.merchandise.compareAtPrice, data.quantity)
			: undefined;
		const isWeird = compareAtWithQuantity
			? data.cost.totalAmount.amount * data.quantity === compareAtWithQuantity.amount
			: false;

		return {
			price: isWeird
				? {
						...data.cost.totalAmount,
						amount: data.cost.totalAmount.amount * data.quantity
					}
				: data.cost.totalAmount,
			compareAtPrice: compareAtWithQuantity
		};
	})();

	$: discountPercentage = percentOf(priceData.price, priceData.compareAtPrice);

	let retries = 0;

	$: if (data.merchandise.product.handle && retries < 3) {
		store
			.fetch({
				variables: {
					sdsHandle: addSDS(data.merchandise.product.handle),
					country: ensureCountry($languageStore.country),
					language: ensureLocale($languageStore.locale)
				}
			})
			.catch((e) => {
				if (isNetworkError(e)) {
					setTimeout(() => retries++, 2000 * (retries + 1));
					return;
				}
				throw e;
			});
	}
</script>

<ProductCardHorizontal
	title={data.merchandise.product.title}
	variantTitle={data.merchandise.title}
	imageUrl={imageData || ''}
	rawHighlights={data.merchandise.product.highlights}
	handle={data.merchandise.product.handle}
	collections={data.merchandise.product.collections}
	selectedOptions={data.merchandise.selectedOptions}
>
	<svelte:fragment slot="price">
		<SaleChip sale={discountPercentage} />
		<div class="font-normal text-opacity-75 text-h-16">
			<ProductPrice price={priceData.price} compareAtPrice={priceData.compareAtPrice} />
		</div>
		<p>
			<UnitPrice
				price={data.merchandise.price}
				measurementPrice={data.merchandise.unitPrice || undefined}
				referenceUnit={data.merchandise.bundle_desc?.value}
				measurement={data.merchandise.unitPriceMeasurement}
			/>
		</p>
	</svelte:fragment>
	<div slot="cart-controls" class="flex flex-col md:flex-row md:items-center w-full mt-3">
		{#key amount}
			<AmountSelection
				maxQuantity={maxAvailable}
				initialAmount={amount}
				bind:amount
				class="w-12"
				wrapperClassName="h-12"
			/>
		{/key}

		<div class="mt-2 lg:mt-0 text-center">
			<Button
				on:click={async () => {
					await cart.removeSpecificLineItem(data.id);
				}}
				class="-mr-4 text-h-14 text-accent-primary hover:underline"
				text={$t('cart.lines.remove')}
			/>
		</div>
	</div>
</ProductCardHorizontal>
