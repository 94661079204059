<script lang="ts">
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { getTranslate } from '@tolgee/svelte';

	export let maxQuantity: number;
	export let initialAmount: number;
	export let amount = initialAmount;
	let className = '';
	export let wrapperClassName = '';
	export { className as class };

	let value = `${initialAmount}`;

	$: {
		(() => {
			const parsedAmount = Number(value);
			if (amount === parsedAmount || value === '') {
				return;
			}
			if (isNaN(parsedAmount) || parsedAmount < 1) {
				amount = 1;
				value = `${amount}`;
				return;
			}
			if (parsedAmount > maxQuantity) {
				amount = maxQuantity;
				value = `${amount}`;
				return;
			}
			if (parsedAmount < 1) {
				amount = 1;
				value = `${amount}`;
				return;
			}
			amount = parsedAmount;
			value = `${parsedAmount}`;
		})();
	}

	const increment = () => {
		value = `${amount + 1}`;
	};

	const decrement = () => {
		value = `${amount - 1}`;
	};

	$: incrementDisabled = amount >= maxQuantity;
	$: decrementDisabled = amount <= 1;
	$: amountSelectionDisabled = incrementDisabled && decrementDisabled;

	const { t } = getTranslate();
</script>

<div
	class={classes(
		'flex items-center border border-colors-dark-quaternary rounded-md focus:outline-1 focus-within:border-accent-pressed justify-between',
		amountSelectionDisabled ? 'bg-dark-background' : '',
		wrapperClassName
	)}
>
	<div class:bg-dark-05={decrementDisabled} class="px-3 self-stretch grid place-items-center">
		<button
			class="i-sprenger-minus w-5 h-5"
			class:opacity-50={decrementDisabled}
			class:pointer-events-none={decrementDisabled}
			on:click={() => decrement()}
		>
		</button>
	</div>
	<input
		class={classes(
			'border-0 m-0 text-center outline-0 outline-none focus:outline-0 self-stretch flex-1 shrink-0 amount-selection-outline bg-transparent',
			amountSelectionDisabled ? 'text-dark-tertiary' : '',
			className
		)}
		pattern="[1-9][0-9]*|[1-9]*"
		bind:value
		on:blur={() => {
			if (value === '') {
				value = '1';
			}
		}}
		type="text"
		disabled={amountSelectionDisabled}
		placeholder={$t('product.quantity')}
	/>

	<div class:bg-dark-05={incrementDisabled} class="px-3 self-stretch grid place-items-center">
		<button
			class="i-sprenger-plus w-5 h-5"
			class:opacity-50={incrementDisabled}
			class:pointer-events-none={incrementDisabled}
			on:click={() => increment()}
		>
		</button>
	</div>
</div>
